<template>
  <div>
    <div
      v-show="loading"
      class="flex justify-center"
    >
      <BufferImage
        class="m-2"
        size="30"
        align="center"
        color="var(--colour-utility-black)"
      />
    </div>
    <div
      v-if="conversation"
      class="email-view"
    >
      <div class="email-header">
        <ul class="email-details">
          <li v-if="conversation?.from">
            <strong>{{ t('From') }}:</strong> {{ formatParticipants(conversation.from) }}
          </li>
          <li v-if="conversation?.to">
            <strong>{{ t('To') }}:</strong> {{ formatParticipants(conversation.to) }}
          </li>
          <li v-if="conversation?.cc">
            <strong>{{ t('Cc') }}:</strong> {{ formatParticipants(conversation.cc) }}
          </li>
          <li v-if="conversation?.bcc">
            <strong>{{ t('Bcc') }}:</strong> {{ formatParticipants(conversation.bcc) }}
          </li>
          <li v-if="modelValue?.latest_message_received_date">
            <strong>{{ t('Latest received date') }}:</strong>
            {{ dates.format(modelValue?.latest_message_received_date, 'shortwtime') }}
          </li>
          <li v-if="modelValue?.latest_message_sent_date">
            <strong>{{ t('Latest sent date') }}:</strong>
            {{ dates.format(modelValue?.latest_message_sent_date, 'shortwtime') }}
          </li>
        </ul>
        <div class="email-cta">
          <CustomButton
            class="py-1"
            purpose="text"
            :label="t('Reply')"
            icon-name="reply"
            icon-color="var(--colour-utility-action)"
            icon-width="20"
            icon-height="20"
            icon-align="right"
            small
            @click.stop="handleReply"
          />
        </div>
      </div>
      <div class="email-body">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-cid-image="modelValue.thread_id"
          class="email-content"
          v-html="conversation.body"
        />
      </div>
    </div>
    <div
      v-else
      class="flex justify-center"
    >
      {{ t('Select a conversation to show') }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t, dates } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import useMail from '@/shared/composables/useMail';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['reply']);

const { formatParticipants } = useMail();

const conversation = computed(() => props.modelValue?.conversation);

const handleReply = () => {
  emit('reply', props.modelValue);
};
</script>

<style lang="scss" scoped>
.email-view {
  display: flex;
  flex-flow: column nowrap;
}

.email {
  margin-bottom: var(--spacing-2);
}
.email-cta {
  display: flex;
  justify-content: flex-end;
}
.email-body {
  text-align: initial;
  flex: 1;
  flex-grow: 1;
}

.email-content {
  margin-bottom: var(--spacing-1);
  padding: var(--spacing-1);
  border: 1px solid var(--shadow-colour);
  overflow: auto;
  max-width: 100%;
  min-height: 10rem;
  border-radius: var(--border-radius-half);
  font-size: smaller;

  img:before {
    background: var(--colour-panel-low);
    border: 1px solid #ccc;
    border-radius: 3px;
    content: '\1F517'' broken image of ' attr(alt);
    display: block;
    left: 0;
    padding: 10px;
    position: absolute;
    top: -10px;
    width: 100%;
  }
}

.email-details {
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-half);
  margin-top: var(--spacing-half);
}
</style>
