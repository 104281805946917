import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR
} from '@/shared/store/actionType';
import { httpRequest, setErrors } from '@sales-i/utils';
import mapContactPayload from '@/crm/store/utils/mapContactPayload';

// mutations
export const putByIdMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.errors = [];
    state.selected.updated = true;
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [PUT_BY_ID_ERROR]: (state, errors) => {
    state.selected.errors = errors;
    state.selected.updated = false;
    state.selected.loading = false;
    state.selected.loaded = false;
  },
};

// actions
export const putByIdActions = {
  [PUT_BY_ID]: async ({ commit, state: { selected, }, }, params) => {
    const { contact_id, payload, entity, entity_id } = params;
    const addressId = selected.data.addresses.length > 0 ? selected.data.addresses[0].id : false;
    commit(PUT_BY_ID_LOADING);
    let contact = mapContactPayload(payload, addressId, contact_id);
    try {
      const data = await httpRequest('put', `${entity}/${entity_id}/contacts/${contact_id}`, contact);
      commit(PUT_BY_ID, {
        ...data,
        ...contact,
      });
    } catch (error) {
      let errorsForForm = error.errors.filter(field => field.code === 'VALIDATION_ERROR');
      commit(PUT_BY_ID_ERROR, errorsForForm);
      if (errorsForForm.length === 0) {
        setErrors(error);
      }
    }
  },
};
