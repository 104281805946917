import {
  APPLY_NEW_SORT,
  APPLY_NEW_FILTER,
  FETCH_BUBBLE_DATA,
  SAVE_BUBBLE_DATA,
} from '@/intelligence/store/actionType';
import { gql } from 'graphql-request';
import setRequestParam from '@/intelligence/store/utils/setRequestParam';
import { BUBBLE_AREA_CUSTOMER, BUBBLE_AREA_PRODUCT, BUBBLE_AREA_SALES } from '@/intelligence/store/data/bubbles';
import { gqlRequest } from '@sales-i/utils';

// initial state
const state = () => ({
  bubbleData: []
});

// getters
const getters = {
  getCustomerBubbleId: state => state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Name').id,
  getCustomerAccountNoBubbleId: state => state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Account Number').id,
  getProductBubbleId: state => state.bubbleData.find(el => el.area === BUBBLE_AREA_PRODUCT && el.title === 'Product Name').id,
};

// mutations
const mutations = {
  [SAVE_BUBBLE_DATA]: (state, data) => {
    state.bubbleData = data;
  },
};

// actions
const actions = {
  [APPLY_NEW_SORT]: ({ commit }, newSortHeader) => {
    setRequestParam(commit, 'currentSortHeader', newSortHeader);
  },
  [APPLY_NEW_FILTER]: ({ commit }, [key, value]) => {
    setRequestParam(commit, key, value);
  },
  [FETCH_BUBBLE_DATA]: async ({ commit }, params) => {
    let bubbleQueryType = 'query';
    let bubbleQuery, parser = undefined;

    if (params !== undefined) {
      [bubbleQueryType, bubbleQuery, parser] = params;
    }
    try {
      const query = gql`{ bubbles(${bubbleQueryType}: "${bubbleQuery || ''}") {
          id
          area
          title
        }}`,
        response = await gqlRequest(query, {}, { debug: true });
      if (parser !== undefined) {
        parser(response.bubbles);
      }
      commit(SAVE_BUBBLE_DATA, response.bubbles);
    } catch (error) {
      console.error(error);
    }
  },
  [SAVE_BUBBLE_DATA]: ({ state, commit }, type) => {
    const customerBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Name').id;
    const productBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_PRODUCT && el.title === 'Product Name').id;
    const transactionBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_SALES).id;
    // const salesRepBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Sales Rep').id;
    setRequestParam(commit, 'snapshotBubbleId', type === 'performance' ? '(here should go salesRepBubbleId later)' : customerBubbleId);
    setRequestParam(commit, 'productBubbleId', productBubbleId);
    setRequestParam(commit, 'transactionBubbleId', transactionBubbleId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
