import {
  GET_AUTH_URL,
  GET_AUTH_URL_LOADING,
  GET_AUTH_URL_ERROR,
  GET_AUTH_STATUS,
  GET_AUTH_STATUS_LOADING,
  GET_AUTH_STATUS_ERROR,
  POST_AUTH_CODE,
  POST_AUTH_CODE_LOADING,
  POST_AUTH_CODE_ERROR,
  DELETE_AUTH_CODE,
  DELETE_AUTH_CODE_LOADING,
  DELETE_AUTH_CODE_ERROR,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';

// getters
export const authGetters = {
};

// mutations
export const authMutations = {
  [GET_AUTH_URL]: (state) => {
    state.url.loading = false;
    state.url.error = false;
  },
  [GET_AUTH_URL_LOADING]: (state) => {
    state.url.loading = true;
    state.url.error = false;
  },
  [GET_AUTH_URL_ERROR]: (state) => {
    state.url.loading = false;
    state.url.error = true;
  },
  [GET_AUTH_STATUS]: (state, data) => {
    state.details.data = data;
    state.details.loading = false;
    state.details.error = false;
  },
  [GET_AUTH_STATUS_LOADING]: (state) => {
    state.details.loading = true;
    state.details.error = false;
  },
  [GET_AUTH_STATUS_ERROR]: (state, e) => {
    state.details.loading = false;
    if (e.status !== 404) {
      state.details.error = true;
    }
  },
  [POST_AUTH_CODE]: (state) => {
    state.details.loading = false;
    state.details.error = false;
  },
  [POST_AUTH_CODE_LOADING]: (state) => {
    state.details.loading = true;
    state.details.error = false;
  },
  [POST_AUTH_CODE_ERROR]: (state) => {
    state.details.loading = false;
    state.details.error = true;
  },
  [DELETE_AUTH_CODE]: (state) => {
    state.url.loading = false;
    state.url.error = false;
  },
  [DELETE_AUTH_CODE_LOADING]: (state) => {
    state.url.loading = true;
    state.url.error = false;
  },
  [DELETE_AUTH_CODE_ERROR]: (state) => {
    state.url.loading = false;
    state.url.error = true;
  }
};

// actions
export const authActions = {
  [GET_AUTH_URL]: async ({ commit, }) => {
    commit(GET_AUTH_URL_LOADING);
    try {
      const url = await httpRequest('get', 'nylas/onboarding/oauth', {}, {
        area: 'ecisync',
        throwErrors: false,
      });
      commit(GET_AUTH_URL);
      return url;
    } catch (e) {
      commit(GET_AUTH_URL_ERROR);
    }
  },
  [POST_AUTH_CODE]: async ({ commit, }, { code, state } = {}) => {
    commit(POST_AUTH_CODE_LOADING);
    try {
      await httpRequest('post', `nylas/onboarding/code?code=${code}&state=${state}`, {}, {
        area: 'ecisync',
        throwErrors: false,
      });
      commit(POST_AUTH_CODE);
    } catch (e) {
      commit(POST_AUTH_CODE_ERROR);
    }
  },
  [DELETE_AUTH_CODE]: async ({ commit, }) => {
    commit(DELETE_AUTH_CODE_LOADING);
    try {
      await httpRequest('delete', 'nylas', {}, {
        area: 'ecisync',
        throwErrors: false,
      });
      commit(DELETE_AUTH_CODE);
    } catch (e) {
      commit(DELETE_AUTH_CODE_ERROR);
    }
  },
};
