const mapContactPayload = (data = {}, address_id = false, contact_id) => {
  const payload = {};
  if (contact_id) {
    payload.id = contact_id;
  }
  const keys = Object.keys(data);
  const addresses = [
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'address_line_4',
    'country',
    'county',
    'post_code',
    'town',
  ];
  let address = {};
  let hasAddress = false;
  keys.forEach(key => {
    const value = data[key];
    if(value !== undefined && value !== null) {
      const valueTrim = typeof value === 'string' ? value.trim() : value;
      if(!addresses.includes(key)) {
        payload[key] = valueTrim;
      } else {
        hasAddress = true;
        address[key] = valueTrim;
      }
    }
  });
  payload.addresses = hasAddress ? [address,] : [];
  if (address_id && payload.addresses.length > 0) {
    payload.addresses[0].id = address_id;
  }
  return payload;
};

export default mapContactPayload;
