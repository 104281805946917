import {
  APPLY_AXIS,
  APPLY_DIMS,
  APPLY_OFFSET_X,
  SET_FILTERS,
  SET_CRM_FILTERS,
  CLEAR_APPLIED_CRM_FILTERS,
  SET_SALES_FILTERS,
  CLEAR_APPLIED_SALES_FILTERS,
  RESET_ALL_DATA_CLAUSES,
  APPLY_FILTERS_TO_QUERY,
  SET_SORTING_DATA,
  DELETE_FILTER,
  SET_PERIOD_FILTER,
  CLEAR_ENQUIRY_FILTERS,
  APPLY_CRM_FILTERS_TO_QUERY,
  APPLY_SALES_FILTERS_TO_QUERY,
  APPLY_MEASURE,
  SALES_DATA_CLAUSE,
  CRM_DATA_CLAUSE,
  PRODUCT_DATA_CLAUSE,
  SET_SHOW_GAPS
} from '@/intelligence/store/actionType';
import { bubbleActions, bubbleMutations } from './bubbles';

import setRequestParam from '@/intelligence/store/utils/setRequestParam';
import { PERIOD_PERIOD } from '@/intelligence/store/data/periods';

function getCriteriaQuery(criteria) {
  return `{id: "${criteria.id}", operator: "${criteria.operator}", value: "${criteria.value}"}`;
}

// initial state
const state = () => ({
  bubbleData: '',
  bubblesLoading: false,
  bubbleAreas: [],
  bubbleValues: [],
  bubblesRequestParameters: {
    query: '',
  },
  dimensionsNumber: 1,
  filtersApplied: [],
  crmFiltersApplied: {},
  salesFiltersApplied: [],
  advancedFiltersApplied: [],
  period: PERIOD_PERIOD,
  salesDataClause: 'AND',
  crmDataClause: 'AND',
  productDataClause: 'AND',
});

// getters
const getters = {
  getPeriod: state => state.period,
};

// mutations
const mutations = {
  ...bubbleMutations,
  [SET_FILTERS]: (state, filterObject) => {
    let filterNumber = state.filtersApplied.length;
    // first, check if the filter already exists in the array (by id)
    const filterIndex = state.filtersApplied.findIndex(el => el.id === filterObject.id);
    // if it does, update the filter number
    if (filterIndex > -1) filterNumber = filterIndex;
    // finally, add the filter to the array
    state.filtersApplied[filterNumber] = filterObject;
  },
  [SET_CRM_FILTERS]: (state, filterObject) => {
    state.crmFiltersApplied = {
      filterClauses: [...filterObject],
      filter_type: 'CUSTOMER', 
    };
  },
  [CLEAR_APPLIED_CRM_FILTERS]: state => {
    state.crmFiltersApplied = {};
  },
  [SET_SALES_FILTERS]: (state, salesFilterObject) => {
    state.salesFiltersApplied = salesFilterObject;
  },
  [SALES_DATA_CLAUSE]: (state, clause) => {
    state.salesDataClause = clause;
  },
  [CRM_DATA_CLAUSE]: (state, clause) => {
    state.crmDataClause = clause;
  },
  [PRODUCT_DATA_CLAUSE]: (state, clause) => {
    state.productDataClause = clause;
  },
  [RESET_ALL_DATA_CLAUSES]: state => {
    state.salesDataClause = 'AND';
    state.crmDataClause = 'AND';
    state.productDataClause = 'AND';
  },
  [CLEAR_APPLIED_SALES_FILTERS]: state => {
    state.salesFiltersApplied = [];
  },
  [DELETE_FILTER]: (state, filterObject) => {
    state.filtersApplied = state.filtersApplied.filter(el => el.id !== filterObject.id);
    // update filter titles
    state.filtersApplied.forEach((filter, index) => {
      filter.title = `Filter ${index+1}`;
    });
  },
  [SET_PERIOD_FILTER]: (state, periodType) => {
    state.period = periodType;
  },
  [CLEAR_ENQUIRY_FILTERS]: state => {
    state.filtersApplied = [];
    state.advancedFiltersApplied = [];
    state.salesFiltersApplied = [];
    state.crmFiltersApplied = {};
  }
};

// actions
const actions = {
  ...bubbleActions,
  [APPLY_AXIS]: ({ commit }, axes) => {
    const {xAxis, yAxis} = axes;
    if (xAxis) {
      setRequestParam(commit, 'xAxis', xAxis);
    }
    if (yAxis) {
      setRequestParam(commit, 'yAxis', yAxis);
    }
  },
  [APPLY_DIMS]: ({ commit }, dims) => {
    const { dim1, dim2 } = dims;

    if (dim1) {
      setRequestParam(commit, 'dim1', dim1);
    }
    if (dim2) {
      setRequestParam(commit, 'dim2', dim2);
    }
  },
  [SET_SORTING_DATA]: async ({ commit }, [newSortHeader, newSortDirection]) => {
    if (newSortHeader)
      setRequestParam(commit, 'currentSortHeader', newSortHeader);
    if (newSortDirection)
      setRequestParam(commit, 'currentSortOption', newSortDirection);
  },
  [APPLY_OFFSET_X]: ({ commit }, offsetX) => {
    setRequestParam(commit, 'offsetX', offsetX);
  },
  [SET_FILTERS]: ({ commit }, filterObject) => {
    commit(SET_FILTERS, filterObject);
  },
  [SET_CRM_FILTERS]: ({ commit}, filterObject) => {
    commit(SET_CRM_FILTERS, filterObject);
  },
  [CLEAR_APPLIED_CRM_FILTERS]: ({ commit }) => {
    commit(CLEAR_APPLIED_CRM_FILTERS);
  },
  [SET_SALES_FILTERS]: ({ commit}, filterObject) => {
    commit(SET_SALES_FILTERS, filterObject);
  },
  [SALES_DATA_CLAUSE]: ({ commit }, clause) => {
    commit(SALES_DATA_CLAUSE, clause);
  },
  [CRM_DATA_CLAUSE]: ({ commit }, clause) => {
    commit(CRM_DATA_CLAUSE, clause);
  },
  [PRODUCT_DATA_CLAUSE]: ({ commit }, clause) => {
    commit(PRODUCT_DATA_CLAUSE, clause);
  },
  [RESET_ALL_DATA_CLAUSES]: ({ commit }) => {
    commit(RESET_ALL_DATA_CLAUSES);
  },
  [CLEAR_APPLIED_SALES_FILTERS]: ({ commit }) => {
    commit(CLEAR_APPLIED_SALES_FILTERS);
  },
  [DELETE_FILTER]: ({ commit }, filterObject) => {
    commit(DELETE_FILTER, filterObject);
  },
  [APPLY_FILTERS_TO_QUERY]: ({ commit, state }, includeChildCustomerData) => {
    let query = '';
    let ignoreDataClauses = false;

    state.filtersApplied.forEach(filter => {
      let selectedValues = '';

      filter.selected_values.forEach(value => {
        selectedValues += `"${value.id}", `;
      });

      if ('ignore_data_clauses' in filter && filter.ignore_data_clauses) {
        ignoreDataClauses = true;
      }

      selectedValues = includeChildCustomerData ? selectedValues : selectedValues.slice(0, -2);
      query += `{
        id: "${filter.id}",
        selected_values: [${selectedValues}],
        filter_type: "${filter.filter_type.toUpperCase()}"${'criteria' in filter === false ? '' : ','}
        ${'type' in filter === false ? '' : `type: "${filter.type}",`}
        ${'criteria' in filter === false ? '' : `criteria: ${getCriteriaQuery(filter.criteria)}`}
        ${'clause' in filter === false ? '' : `clause: "${filter.clause}"`}
      },`;
    });

    // If clauseOption is 'OR' then commit filtersOR. Otherwise, commit filters
    // this does cause problems with other queries, if we're using 
    if (!ignoreDataClauses && state.productDataClause === 'OR') {
      setRequestParam(commit, 'filtersOR', query);
    } else {
      setRequestParam(commit, 'filters', query);
    }
  },
  [APPLY_CRM_FILTERS_TO_QUERY]: ({ commit, state }) => {
    let query = `{
      id: "1",
      filter_type: "${state.crmFiltersApplied.filter_type === undefined 
    ? 'CUSTOMER' 
    : state.crmFiltersApplied.filter_type}",
      filter_clauses: [
    `;

    (state.crmFiltersApplied.filterClauses ?? []).map(filter => {
      let validFilterValue = filter.value !== '' && filter.value !== undefined && filter.value !== null;

      // If any of these fields are empty, skip
      if (
        filter.entity === '' ||
        filter.field === '' ||
        filter.is === '' ||
        !validFilterValue ||
        filter.clause === ''
      ) {
        return;
      }
      query += `{
        entity: "${filter.entity}",
        field: "${filter.field}",
        is: "${filter.is}",
        value: ${Array.isArray(filter?.value) 
    ? JSON.stringify(filter.value.filter(v => v != null)) 
    : `["${filter.value}"]`},
        clause: "${filter.clause}",
      },`;
    });
    query += ']}';

    // If clauseOption is 'OR' then commit crmFilterOR. Otherwise, commit crmFilter
    if (state.crmDataClause === 'OR') {
      setRequestParam(commit, 'crmFilterOR', query);
    } else {
      setRequestParam(commit, 'crmFilter', query);
    }
  },
  [APPLY_SALES_FILTERS_TO_QUERY]: ({ commit, state }) => {
    let query = '';
    state.salesFiltersApplied.forEach(filter => {
      query += `{
        id: "${filter.id}",
        type: "${filter.type}",
        field: "${filter.field}",
        operator: "${filter.operator}",
        value: "${filter.value}",
      },`;
    });

    // If clauseOption is 'OR' then commit salesFilterOR. Otherwise, commit salesFilter
    if (state.salesDataClause === 'OR') {
      setRequestParam(commit, 'salesFilterOR', query);
    } else {
      setRequestParam(commit, 'salesFilter', query);
    }
  },
  [CLEAR_ENQUIRY_FILTERS]: ({ commit }) => {
    commit(CLEAR_ENQUIRY_FILTERS);
  },
  [SET_PERIOD_FILTER]: ({ commit }, periodType) => {
    commit(SET_PERIOD_FILTER, periodType);
  },
  [APPLY_MEASURE]: ({ commit }, measure) => {
    setRequestParam(commit, 'measure', measure);
  },
  [SET_SHOW_GAPS]: ({ commit }, showGaps) => {
    setRequestParam(commit, 'showGaps', showGaps);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
