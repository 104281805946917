import { getMailAttachment } from '@sales-i/utils';
import placeholderImageUrl from '@/shared/assets/image/noimage.svg';

export const scrollIntoView = () => {
  document.getElementById('single-spa-application:@sales-i/crm').scrollIntoView();
};

/**
 * Scroll to the top of a page when this directive is added to the <router-link> tag
 */
const scrollToTop = {
  beforeMount: function (el) {
    el.addEventListener('click', () => {
      scrollIntoView();
    });
  }
};

export const cidImage = {
  mounted(el, binding) {
    processImages(el, binding.value);
  },
  updated(el, binding) {
    processImages(el, binding.value);
  }
};

async function processImages(el, messageId) {
  const imgs = el.getElementsByTagName('img');
  
  for (const img of imgs) {
    if (img.src.startsWith('cid:')) {
      const cid = img.src.replace('cid:', '');
      try {
        const response = await getMailAttachment({
          message_id: messageId,
          attachment_id: cid
        });
        // Assuming the response contains the image data as a base64 string
        img.src = `data:image/png;base64,${response.data}`;
      } catch (error) {
        console.error('Failed to fetch attachment:', error);
        img.src = placeholderImageUrl; // Fallback image
      }
    }
  }
}


export { scrollToTop };
