<template>
  <TabsAndTagsLayout
    entity="customer"
    :entity-id="customerId"
    :title="selectedCustomer.name"
    :tabs="tabs"
    :tab-selected-index="tabIndex"
    :show-add-interaction="showAddInteraction"
    @tab-selected="handleTabSelected"
  >
    <template #before>
      <div class="rollup-wrap">
        <RolledUpCollection
          v-if="isRolledUp"
          v-bind="customerChildren"
          :platform="platform"
          :navigation="tabs"
          :current-id="selectedCustomer.id"
          :parent-id="selectedCustomer.parent_account_id"
        />
        <div
          v-if="(customerHasChildren && !isRolledUp) || (selectedCustomer.id !== selectedCustomer.parent_account_id)"
          class="roll-up-button padded"
        >
          <IconButton
            v-if="selectedCustomer.id !== selectedCustomer.parent_account_id && !isRolledUp"
            icon-name="arrow-up"
            icon-color="white"
            small
            @on-click="goToParent"
          />
          <IconButton
            v-if="customerHasChildren && !isRolledUp"
            icon-name="arrow-down"
            icon-color="white"
            small
            @on-click="handleRollup"
          />
        </div>
      </div>
    </template>
    <template #title>
      <h1
        v-if="selectedCustomer.name"
        :class="mq.current"
      >
        {{ selectedCustomer.name }}
        <CustomButton 
          v-if="opportunitiesRoundelCount > 0"
          class="opportunities-roundel"
          purpose="text"
          @on-click="navigateToUrl(opportunitiesTabUrl)"
        >
          {{ opportunitiesRoundelCount }}
        </CustomButton>
      </h1>
    </template>
    <template #subtitle>
      <h4 class="subtitle">
        {{ selectedCustomer.account_number }}
      </h4>
    </template>
    <template #headerBottom>
      <PrimaryDetailsBar
        :email="selectedCustomer.email"
        :phone="selectedCustomer.tel_1"
        :addresses="selectedCustomer.addresses"
      />
      <CustomerSegments
        :customer-id="customerId"
      />
    </template>
    <template #content>
      <div v-if="showRouterView">
        <router-view
          :id="customerId"
          :key="customerOverviewKey"
          :permissions="permissionsSet"
          :sections-titles="sectionsTitles"
          :children="customerChildren"
          :include-child-customer-data="includeChildCustomerData"
          :dates="dates"
          :date-params="dateParams"
          :entity-name="selectedCustomer.name"
          :is-contact-view="isContactView"
          @date-updated="updateDates"
          @refresh-parent="fetchCustomerDataWithChildren"
          @toggle-custom-schema="toggleCustomSchema"
        />
      </div>
      <BufferImage
        v-else
        class="loading-spinner"
        color="var(--colour-utility-black)"
        float="center"
      />
    </template>
  </TabsAndTagsLayout>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { useMq } from 'vue3-mq';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { IconButton, BufferImage, CustomButton } from '@sales-i/dsv3';
import TabsAndTagsLayout from '@/shared/components/Layout/TabsAndTagsLayout.vue';
import RolledUpCollection from '@/crm/components/Rollup/RolledUpCollection.vue';
import { baseUrl, customersArea, contactsSection, filesSection, postsSection, detailsSection, customSectionsSection, productGapSection, interactionsHistory, opportunitiesArea } from '@/crm/router/urlBits';
import { contacts, customers, interactions, posts_comments, files, product_associations, opportunities } from '@/shared/store/data/policies';
import PrimaryDetailsBar from '@/crm/components/Customer/PrimaryDetailsBar.vue';
import { useCustomer } from '@/crm/composables/useCustomer';
import { useCustomSchema } from '@/admin/composables/useCustomSchema';
import { useDates } from '@/intelligence/composables/useDates';
import usePermissions from '@/shared/composables/usePermissions';
import { useOpportunity } from '@/crm/composables/useOpportunity';

const ENTITY_FOR_CUSTOM_SCHEMA = 'prospects';

const store = useStore();
const vroute = useRoute();
const mq = useMq();
const { getPermissions } = usePermissions();
const { opportunitiesSummary } = useOpportunity();

const platform = ref('mobile');
const childId = ref(false);
const tabIndex = ref(0);
const showAddInteraction = ref(true);
const customerOverviewKey = ref(0);
const includeChildCustomerData = ref(false);
const isContactView = ref(false);

/** COMPUTED */
const showRouterView = computed(() => selectedCustomerLoaded.value && Object.keys(selectedCustomer.value).length > 0);
const sectionsTitles = computed(() => schemaSections.value.customSections);
const opportunitiesRoundelCount = computed(() => opportunitiesSummary.value?.summary_overview?.opportunity_count_open || 0);
const opportunitiesTabUrl = computed(() => `${baseUrl}/${customersArea}/${childId.value || customerId.value}/${opportunitiesArea}`);

// permissions for customSections
const permissionsSet = computed(() => ({
  entity: getPermissions(customers) 
    ? {
      ...getPermissions(customers),
      canUpdateCustomSchema: getPermissions(customers).read
    } 
    : {},
  schema: getPermissions(customers),
}));

const tabs = computed(() => {
  const id = childId.value || customerId.value;
  let arr = [
    {
      title: t('Overview'),
      path: `${baseUrl}/${customersArea}/${id}`,
      key: 'overview',
    },
    {
      title: t('Details'),
      path: `${baseUrl}/${customersArea}/${id}/${detailsSection}`,
      key: 'details',
    },
  ];
  if (getPermissions(contacts).read) {
    arr.push({
      title: t('Contacts'),
      path: `${baseUrl}/${customersArea}/${id}/${contactsSection}`,
      key: contactsSection,
    });
  }
  if (getPermissions(customers).read) {
    arr.push({
      title: t('Custom sections'),
      path: `${baseUrl}/${customersArea}/${id}/${customSectionsSection}`,
      key: customSectionsSection,
    });
  }
  if (getPermissions(files).read) {
    arr.push({
      title: t('Files'),
      path: `${baseUrl}/${customersArea}/${id}/${filesSection}`,
      key: filesSection,
    });
  }
  if (getPermissions(posts_comments).read) {
    arr.push({
      title: t('Posts'),
      path: `${baseUrl}/${customersArea}/${id}/${postsSection}`,
      key: postsSection,
    });
  }
  if (getPermissions(product_associations).read) {
    arr.push({
      title: t('Gaps'),
      path: `${baseUrl}/${customersArea}/${id}/${productGapSection}`,
      key: productGapSection,
    });
  }
  if (getPermissions(interactions).read) {
    arr.push({
      title: t('Interactions history'),
      path: `${baseUrl}/${customersArea}/${id}/${interactionsHistory}`,
      key: interactionsHistory,
    });
  }
  if (getPermissions(opportunities).read) {
    arr.push({
      title: t('Opportunities'),
      path: `${baseUrl}/${customersArea}/${id}/${opportunitiesArea}`,
      key: opportunitiesArea,
    });
  }
  return arr;
});
const customerId = computed(() => vroute.params.id);

watch(() => ({ params: vroute.params, path: vroute.path }), (to, from) => {
  setSelectedIndex(to.path);
  const toId = to.params.id;
  if (toId && to.path.includes('customer') && toId !== from.params.id) {
    getCustomerById({ id: toId });
    getCustomerChildren({id: toId});
    setRollUp(false);
  }
});

onMounted(async() => {
  const path = decodeURI(vroute.path);
  window.addEventListener('resize', setPlatform);
  setSelectedIndex(path);
  setPlatform();
  loadSchema({ entity: ENTITY_FOR_CUSTOM_SCHEMA });
  await updateDates();
  await getCustomerById({ id: customerId.value });
  await updateRecentlyViewedCustomer(customerId.value);
  fetchCustomerDataWithChildren(true);
});

onUnmounted(() => {
  window.removeEventListener('resize', setPlatform);
  clearCustomerById();
  clearCustomerChildren();
});

/** COMPOSABLES */
const {
  customerChildren,
  customerHasChildren,
  selectedCustomer,
  selectedCustomerLoaded,
  isRolledUp,
  getCustomerById,
  getCustomerChildren,
  clearCustomerChildren,
  clearCustomerById,
  setRollUp,
  updateRecentlyViewedCustomer
} = useCustomer({ store });
const { schemaSections, loadSchema } = useCustomSchema({ store, enableOnMounted: false, enableWatch: false });
const { dates, dateParams, updateDates } = useDates({ store });

/** METHODS */
const setPlatform = () => {
  // this local instance of platform is used for the Roll Up collection, it is unique for the Customer View
  platform.value = window.screen.width >= 768 ? 'desktop' : 'mobile';
};

const handleTabSelected = data => {
  const { index, item } = data;
  tabIndex.value = index;
  if (item.title !== 'More' && decodeURI(vroute.path) !== item.path) {
    navigateToUrl(item.path);
  }
  isContactView.value = false;
};

const setSelectedIndex = path => {
  tabIndex.value = tabs.value.findIndex(e => {
    const keys = path.split('/');
    return keys.includes(e.key);
  });
  if (tabIndex.value === -1) tabIndex.value = 0;
};

const handleRollup = () => {
  getCustomerChildren({ id: customerId.value });
  setRollUp(true);
};

const goToParent = () => {
  navigateToUrl(determineRoute(selectedCustomer.value.parent_account_id));
};

const determineRoute = id => {
  const childRoutes = tabs.value.map(item => item.key);
  let parts = vroute.path.split('/');
  let childRoute = parts.find(part => childRoutes.includes(part));
  return childRoute ? `${baseUrl}/${customersArea}/${id}/${childRoute}` : `${baseUrl}/${customersArea}/${id}`;
};

async function fetchCustomerDataWithChildren(value) {
  await getCustomerChildren({ id: customerId.value, rolldown: value });
  includeChildCustomerData.value = value;
  customerOverviewKey.value++;
}

function toggleCustomSchema() {
  isContactView.value = !isContactView.value;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.rollup-wrap {
  display: flex;
  > div {
    width: 100%;
  }
  .roll-up-button {
    display: flex;
    justify-content: flex-end;
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

h1 {
  position: relative;
}

.opportunities-roundel {
  position: absolute;
  font-size: var(--font-size-body);
  color: var(--colour-data-mandy-label);
  background: var(--colour-data-mandy-light);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
</style>
