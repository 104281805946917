import singleSpaVue from 'single-spa-vue';
import { h, createApp } from 'vue';
import { createHead } from '@unhead/vue';
import App from '@/App.vue';
import router from '@/routers';
import { Vue3Mq } from 'vue3-mq';
import store from '@/shared/store';
import { scrollToTop, cidImage } from '@/shared/directives';
import '@/shared/assets/dsv3/css/main.css';
import '@/shared/assets/scss/main.scss';
import 'floating-vue/dist/style.css';
import breakpoints from '@/shared/utils/breakpoints';

window['FE_CRM_VERSION'] = process.env.VUE_APP_FE_CRM_VERSION;

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  handleInstance: (app) => {
    const head = createHead();
    app.use(store);
    app.use(router);
    app.use(Vue3Mq, {
      breakpoints: breakpoints.breakpoints,
      defaultBreakpoint: breakpoints.defaultBreakpoint,
    });
    app.use(head);
    app.directive('cid-image', cidImage);
    app.directive('scroll-to-top', scrollToTop);
    app.config.globalProperties.$currency = process.env.VUE_APP_TENANT_CURRENCY;
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
