import {
  UPLOAD_ENTITY_FILE_LOADING,
  UPLOAD_ENTITY_FILE,
  UPLOAD_ENTITY_FILE_ERROR
} from '@/crm/store/actionType';
import { fileUpload } from '@sales-i/utils';

const defaultOptions = {
  area: 'files',
  version: '2',
  config: {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
};

// mutations
export const uploadMutations = {
  [UPLOAD_ENTITY_FILE_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
    state.selected.data = {};
    state.selected.errors = [];
  },
  [UPLOAD_ENTITY_FILE_ERROR]: (state, errors) => {
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.data = {};
    state.selected.errors = errors;
  },
  [UPLOAD_ENTITY_FILE]: (state, data) => {
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.errors = [];
  }
};

// actions
export const uploadActions = {
  [UPLOAD_ENTITY_FILE]: async ({ commit }, parameters = {}) => {
    try {
      let { options, entity_type, entity_id, file, name } = parameters;
      options = {...defaultOptions, ...options};
      commit(UPLOAD_ENTITY_FILE_LOADING);
      let formData = new FormData();
      formData.append('file', file.file);
      formData.append('alias', name);
      formData.append('description', name);
      let response = await fileUpload(`${entity_type}/${entity_id}/upload`, formData, options);
      commit(UPLOAD_ENTITY_FILE, response);
      return true;
    } catch (error) {
      console.error(error);
      commit(UPLOAD_ENTITY_FILE_ERROR, error);
      return false;
    }
  },
};

