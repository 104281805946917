import { computed } from 'vue';
import { useStore } from 'vuex';
import { DELETE_AUTH_CODE, DELETE_CALENDAR, GET_AUTH_URL, GET_CALENDAR, POST_AUTH_CODE, PUT_PRIMARY_CALENDAR } from '@/admin/store/actionType';

export default function useMailAdmin({ store = useStore() } = {}) {
  const urlLoading = computed(() => store.state.admin.email.url.loading);
  const urlError = computed(() => store.state.admin.email.url.error);

  const getAuthUrl = () => store.dispatch(`admin/email/${GET_AUTH_URL}`);
  const postAuthCode = params => store.dispatch(`admin/email/${POST_AUTH_CODE}`, params);
  const deleteAuthCode = () => store.dispatch(`admin/email/${DELETE_AUTH_CODE}`);
  const getCalendar = () => store.dispatch(`admin/email/${GET_CALENDAR}`);
  const putPrimaryCalendar = calendarId => store.dispatch(`admin/email/${PUT_PRIMARY_CALENDAR}`, calendarId);
  const deleteCalendar = () => store.dispatch(`admin/email/${DELETE_CALENDAR}`);
  
  return {
    urlLoading,
    urlError,
    getAuthUrl,
    postAuthCode,
    deleteAuthCode,
    getCalendar,
    putPrimaryCalendar,
    deleteCalendar
  };
}