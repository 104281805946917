<template>
  <CustomModal
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="800"
    :title="t('Generate interactions')"
    class="generate-interactions-modal"
    @close-modal="close"
  >
    <div class="rendered-list-column">
      <div class="search-item-container">
        <div class="search-item">
          <input
            v-model="searchQuery"
            class="input"
            type="text"
            :aria-label="t('Search customers')"
            :placeholder="t('Search customers')"
          >
          <CustomButton
            class="search-button"
            purpose="transparent"
            icon-name="search"
            icon-only
            :icon-width="22"
            :icon-height="22"
          />
        </div>
      </div>
      <ul class="rendered-list">
        <BufferImage
          v-if="bubblesLoading"
          color="var(--colour-utility-black)"
          float="center"
          class="loading-spinner"
        />
        <template v-if="!bubblesLoading && !isFiltering">
          <GenerateInteractionsForm
            :customer-list="renderedList"
            @customer-selection="customerSelection"
          />
        </template>
      </ul>
    </div>

    <template #footer>
      <ButtonGroup>
        <CustomButton
          :label="t('Generate')"
          purpose="action"
          small
          :disabled="!isValid"
          :class="{ save: true, disabled: !isValid }"
          @on-click="generateInteractions"
        />
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import GenerateInteractionsForm from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionsForm.vue';
import { BufferImage, CustomModal, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { acceptedDimensions, ACCOUNT_NUMBER } from '@/intelligence/components/ReportFooter/acceptedDimensions';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits.js';
import {
  baseUrl as intelligenceBaseUrl,
  customerScope,
  insightsScope,
  alertsScope,
} from '@/intelligence/router/urlBits.js';
import { FULL_PICTURE, VARIANCE, QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { debounce, t } from '@sales-i/utils';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useSystem from '@/shared/composables/useSystem';
import useShared from '@/intelligence/composables/useShared';
import { useInteraction } from '@/crm/composables/useInteraction';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  entityType: {
    type: String,
    default: 'customer',
  },
  reportType: {
    type: [String, Boolean],
    default: false,
  },
});

const emit = defineEmits(['close', 'input']);

const store = useStore();
const vroute = useRoute();

const { bubblesLoading } = useEnquiries({ store });
const { setRoute } = useSystem({ store });
const { fetchedData } = useShared({ store });
const { setEntityId } = useInteraction({ store });

const searchQuery = ref('');
const isFiltering = ref(false);
const entityIDs = ref([]);
const renderedList = ref([]);

const getSavedAlertsCustomers = computed(() => store.getters['intelligence/savedQuery/uniqueCustomers']);
const isFormDirty = computed(() => entityIDs.value.length > 0);
const isValid = computed(() => entityIDs.value.length > 0 && entityIDs.value.every(item => item !== null));

const customerList = computed(() => {
  let list = [];

  if (vroute.path.includes(`${alertsScope}`)) {
    // For Saved Alerts
    if (getSavedAlertsCustomers.value) {
      list = getSavedAlertsCustomers.value.map(({ id, title }) => ({
        id,
        text: title,
        checked: false,
      }));
    }
  } else {
    const selectedData = Object.values(fetchedData.value)
      .filter((item) => item.reportType === props.reportType)
      .map((item) => item.data)
      .flat();

    const selectedRows = Object.values(fetchedData.value)
      .filter((item) => item.reportType === props.reportType)
      .map((item) => item.data.rows)
      .flat();

    if (selectedData.length > 0 && selectedData[0].axis) {
      // For Matrix/SVG queries
      list = getListFromMatrix(selectedData, selectedRows);
    } else if (
      selectedData.length &&
      vroute.path.includes(`${intelligenceBaseUrl}/${insightsScope}/${customerScope}`)
    ) {
      // For Customer Insights reports
      list = getListFromCustomerInsights(selectedData);
    } else if (
      [FULL_PICTURE, VARIANCE, QUERY_BUILDER].includes(props.reportType) &&
      selectedRows.length
    ) {
      // For Full Picture / Variance reports
      list = getListFromFullPicture(selectedData, selectedRows);
    }
  }
  return list;
});

function getListFromMatrix(selectedData, selectedRows) {
  let headingIndex = 0;

  const customerIDs = selectedRows.map((row) => {
    return {
      id: row.dimension_values?.[0]?.id || null,
      customer_no: row.dimension_values?.[0]?.values?.[1] || null,
    };
  });

  if (selectedData[0]?.axis?.y?.entity === ACCOUNT_NUMBER) {
    headingIndex = 1;
  }

  let list = selectedData
    .map((item) => getAxisYHeadings(item.axis.y))
    .flat()
    .map((item) => ({
      text: item[headingIndex],
      customer_no: item[headingIndex === 0 ? 1 : 0],
      checked: false,
    }));

  // Merge customerIDs and customerNames into one array
  list = list.map((item, index) => ({
    ...item,
    ...(customerIDs[index] || {}),
  }));

  return list;
}

function getListFromCustomerInsights(selectedData) {
  const dataToMap = selectedData[0].customers || selectedData;
  return dataToMap.map(({ id, customer, name }) => ({
    id,
    text: customer || name,
    checked: false,
  }));
}

function getListFromFullPicture(selectedData, selectedRows) {
  if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
    console.error('selectedRows is empty or not an array');
    return [];
  }

  let lookupIndex = 1;
  let headingIndex = 0;

  if (
    selectedData[0]?.headings?.dimensions?.[1] &&
    acceptedDimensions.includes(selectedData[0].headings.dimensions[1]) &&
    selectedData[0].headings.dimensions[1] === ACCOUNT_NUMBER
  ) {
    headingIndex = 1;
  }
  if (acceptedDimensions.includes(selectedData[0]?.headings?.dimensions?.[0])) {
    lookupIndex = 0;
    headingIndex = selectedData[0].headings.dimensions[0] === ACCOUNT_NUMBER ? 1 : 0;
  }

  let list = selectedRows.map((item) => {
    const dimensionsArray = item.dimensions || [];
    const dimensionItem = dimensionsArray[lookupIndex] || dimensionsArray[0] || {};
    const dimensionValues = dimensionItem.dimensions || [];

    const text = dimensionValues[headingIndex] || '';
    const id = dimensionItem.entity_id || null;

    return {
      text,
      id,
      checked: false,
    };
  });

  // Remove duplicates based on id
  const uniqueIds = new Set();
  return list.filter((item) => item.id && !uniqueIds.has(item.id) && uniqueIds.add(item.id));
}

function getAxisYHeadings(axisY) {
  if (Array.isArray(axisY)) {
    return axisY.reduce((acc, yItem) => acc.concat(yItem.headings || []), []);
  } else if (axisY?.headings) {
    return axisY.headings;
  }
  return [];
}

const filterList = async () => {
  isFiltering.value = true;
  await nextTick();
  renderedList.value = customerList.value.filter((option) =>
    option.text.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
  isFiltering.value = false;
};

const resetFilterList = () => {
  renderedList.value = customerList.value;
};

watch(
  searchQuery,
  debounce((newValue) => {
    newValue.length ? filterList() : resetFilterList();
  }, 500)
);

onMounted(() => {
  renderedList.value = customerList.value;
});

const close = () => {
  emit('close');
};

const customerSelection = (value) => {
  entityIDs.value = value;
};

const generateInteractions = async () => {
  const generatedEntityIDs = entityIDs.value.map((item) => Number(item));
  await setEntityId(generatedEntityIDs);
  setRoute({
    success: vroute.fullPath,
    cancel: vroute.fullPath,
  });
  navigateToUrl(`${baseUrl}/${interactionsArea}/generate-customer-interaction`);
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

:deep(.content-wrapper) {
  padding-bottom: 0;
}

.generate-interactions-modal {
  .form-grid {
    padding: 0 var(--spacing-2);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: 0 var(--spacing-3);
    }

    & > :deep(.row) {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.modal-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1) center center no-repeat;
  display: flex;
}

.search-item-container {
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-1);
  background: var(--colour-panel-action);

  .search-item {
    position: relative;
    margin-bottom: var(--spacing-1);
  }
}

.input {
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--spacing-half) var(--shadow-spread) var(--colour-panel-g-16);
  border-radius: var(--spacing-4);
  font-size: var(--font-size-small);
  line-height: var(--spacing-2);
  font-weight: var(--font-weight-regular);
  padding-left: var(--spacing-5);
}

.search-button {
  position: absolute;
  top: 50%;
  left: var(--spacing-1);
  transform: translateY(-50%);
}

.rendered-list {
  overflow-y: auto;
  height: 330px;
  max-height: calc(100vh - 400px);

  .loading-spinner {
    position: absolute;
    top: calc(50% + 50px);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }
}
</style>
