<template>
  <div class="bg container container--fluid">
    <BufferImage
      v-if="loading && data.length <= 0"
      color="var(--colour-utility-black)"
      float="center"
    />
    <div
      v-if="isModalView"
      class="modal-bg"
    />
    <div
      v-if="data.length > 0 && platform === 'desktop'"
      class="rollup_accounts padded pt-0"
    >
      <div class="carousel_container">
        <button
          v-if="platform === 'mobile'"
          class="close-btn"
          @click="event => rolldown(event)"
        >
          <IconBase
            icon-name="close-line"
            icon-color="var(--colour-utility-action)"
            :height="32"
            :width="32"
          />
        </button>
        <div
          v-if="scrollLeft > 24 && platform === 'desktop'"
          ref="carousel_prev_arrow"
          class="carousel_arrow left"
        >
          <IconButton
            purpose="transparent"
            icon-name="chevron-left"
            icon-color="var(--colour-utility-action)"
            :icon-size="48"
            @on-click="prevSlide"
          />
        </div>
        <div
          ref="rollup_list"
          class="carousel_list"
          @scroll="handleScroll"
        >
          <button
            v-for="(item, index) in data"
            :key="index"
            :ref="`childRefs.${item.id}`"
            :class="`carousel_slide ${selectedChild === item.id ? 'selected' : ''}`"
            @click="itemClicked(item)"
          >
            <span class="account-name">{{ item.name }}</span>
            <span class="account-no">{{ item.account_number }}</span>
          </button>
        </div>
        <div
          v-if="displayScrollRight && platform === 'desktop'"
          ref="carousel_next_arrow"
          class="carousel_arrow right"
        >
          <IconButton
            purpose="transparent"
            icon-name="chevron-right"
            icon-color="var(--colour-utility-action)"
            :icon-size="48"
            @on-click="nextSlide"
          />
        </div>
      </div>
      <div
        v-if="platform === 'desktop'"
        ref="rolldownRef"
        class="rolldown_container"
      >
        <button
          v-if="totalAccounts >= 30"
          class="view-all-btn"
          @click="event => openModal(true)"
        >
          <IconBase
            icon-name="more"
            icon-color="var(--colour-utility-white)"
            :height="24"
            :width="24"
          />
        </button>
        <IconButton
          v-if="currentId !== parentId"
          icon-name="arrow-up"
          icon-color="var(--colour-utility-white)"
          purpose="action"
          @on-click="goToParent"
        />
        <IconButton
          icon-name="arrow-down"
          icon-color="var(--colour-utility-white)"
          purpose="neutral"
          @on-click="rolldown"
        />
      </div>
    </div>
    <div
      v-if="isModalView"
      class="desktop-list-view"
    >
      <button
        class="close-btn"
        @click="event => (platform === 'mobile' ? rolldown(event) : openModal(false, event))"
      >
        <IconBase
          icon-name="close-line"
          icon-color="var(--colour-utility-action)"
          :height="32"
          :width="32"
        />
      </button>
      <div class="carousel-modal-list">
        <button
          v-for="(item, index) in data"
          :ref="`child-${item.id}`"
          :key="index"
          :class="`carousel_slide desktop ${selectedChild === item.id ? 'selected' : ''}`"
          @click="itemClicked(item)"
        >
          <span class="account-name">{{ item.name }}</span>
          <span class="account-no">{{ item.account_number }}</span>
        </button>
      </div>
    </div>
    <div
      v-if="platform === 'mobile' && isChildView"
      class="rollup-mobile-heading"
    >
      <IconButton
        label="Rollup"
        icon-name="arrow-up"
        icon-color="white"
        @on-click="openModal(true)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, onUpdated } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { baseUrl, customersArea } from '@/crm/router/urlBits';
import { BufferImage, IconBase, IconButton } from '@sales-i/dsv3';
import { CLEAR_CHILDREN, ROLL_UP } from '@/crm/store/actionType';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const vroute = useRoute();

const props = defineProps({
  navigation: {
    type: Array,
    default: () => [],
  },
  platform: {
    type: String,
    default: '',
  },
  data: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loaded: {
    type: Boolean,
    default: true,
  },
  currentId: {
    type: [String, Number],
    default: 0,
  },
  parentId: {
    type: [String, Number],
    default: 0
  }
});

const rollup_list = ref(null);
const rolldownRef = ref(null);
const carousel_next_arrow = ref(null);
const carousel_prev_arrow = ref(null);

const selectedChild = ref(false);
const isChildView = ref(false);
const isModalView = ref(props.platform === 'mobile');
const currentSlide = ref(0);
const scrollLeft = ref(0);
const displayScrollRight = ref(false);

const childRoutes = computed(() => props.navigation.map(item => item.key));
const totalAccounts = computed(() => props.data.length);

onMounted(() => {
  window.addEventListener('resize', reset);
});
onUpdated(() => {
  if (rollup_list.value) {
    let len = rollup_list.value.children.length;
    let bounds = rollup_list.value.children[len - 1].getBoundingClientRect();
    if (bounds.x + bounds.width > rolldownRef.value.getBoundingClientRect().x) {
      displayScrollRight.value = true;
    }
  }
});
onUnmounted(() => {
  window.removeEventListener('resize', reset);
  rolldown();
});

const clearChildren = () => store.dispatch(`crm/customers/${CLEAR_CHILDREN}`);
const setRollUp = bool => store.dispatch(`crm/customers/${ROLL_UP}`, bool);

const reset = () => {
  if (props.platform !== 'mobile' && isModalView) {
    isModalView.value = false;
  }
  if (props.platform === 'mobile' && !isChildView.value) {
    isModalView.value = true;
  }
  currentSlide.value = 0;
  scrollLeft.value = 0;
  displayScrollRight.value = false;
};
const itemClicked = item => {
  isModalView.value = false;
  navigateToUrl(determineRoute(item.id));
  clearChildren();
};
const determineRoute = id => {
  let parts = vroute.path.split('/');
  let childRoute = parts.find(part => childRoutes.value.includes(part));
  return childRoute ? `${baseUrl}/${customersArea}/${id}/${childRoute}` : `${baseUrl}/${customersArea}/${id}`;
};
const rolldown = event => {
  event?.stopPropagation();
  if (props.platform === 'mobile' && isChildView.value && isModalView.value) {
    openModal(false);
  } else {
    setRollUp(false);
  }
};
const openModal = (open, event) => {
  event?.stopPropagation();
  isModalView.value = open;
};
const handleScroll = () => {
  scrollLeft.value = rollup_list.value.scrollLeft;
  let totalWidth = 0;
  if (rollup_list.value) {
    for (let i = 0, len = totalAccounts; i < len.value; i++) {
      totalWidth += rollup_list.value.children[i].offsetWidth;
    }
  }
  displayScrollRight.value = totalWidth > 0 && totalWidth - 24 > scrollLeft.value + rollup_list.value.clientWidth;
};
const prevSlide = () => {
  currentSlide.value -= 1;
  rollup_list.value.scroll({
    left: rollup_list.value.clientWidth * (currentSlide.value * 0.75),
    behavior: 'smooth',
  });
};
const nextSlide = () => {
  currentSlide.value += 1;
  rollup_list.value.scroll({
    left: rollup_list.value.clientWidth * (currentSlide.value * 0.75),
    behavior: 'smooth',
  });
};
const goToParent = () => {
  navigateToUrl(determineRoute(props.parentId));
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: var(--colour-panel-action);
}

.modal-bg {
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.48;
  background-color: var(--colour-utility-black);
  @media (min-width: 768px) {
    z-index: 102;
  }
}
.rollup_accounts {
  position: fixed;
  left: 5%;
  width: 90%;
  z-index: 10;
  background-color: var(--colour-panel-action);
  border-radius: var(--border-radius-1);
  top: 80px;
  max-height: calc(((100vh - 80px) / 10) * 9);
  overflow-y: auto;
  @media (min-width: 768px) {
    position: relative;
    z-index: 0;
    display: flex;
    left: 0;
    width: 100%;
    border-radius: 0;
    top: 0;
    max-height: none;
    overflow-y: hidden;
  }
}
.return_to_parent {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: var(--font-weight-semibold);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-size: 12px;
  .icon {
    margin-right: var(--spacing-1);
  }

  @media (min-width: 768px) {
    font-size: 16px;
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-2);
  }
}

.carousel_container {
  position: relative;
  overflow: hidden;
  flex: 1;
}
.close-btn {
  position: absolute;
  z-index: 1;
  top: var(--spacing-2);
  right: var(--spacing-2);
}
.carousel_arrow {
  transform: translate3d(0, 0, 0); // just for Safari z-index issue on iOS 13
  -webkit-transform: translate3d(0, 0, 0); // just for Safari z-index issue on iOS 13
  position: absolute;
  background-color: var(--colour-panel-action);
  z-index: 1;
  top: -3px;
  padding-top: var(--spacing-2);
  height: calc(100% + var(--spacing-half));
  &.left {
    width: calc(48px + var(--spacing-2));
    left: -1px;
    &:after {
      position: absolute;
      top: 0px;
      left: calc(48px + var(--spacing-2));
      content: '';
      width: 20px;
      height: calc(100% + 20px);
      opacity: 0.16;
      background: linear-gradient(90deg, black, transparent);
    }
  }
  &.right {
    right: -1px;
    width: 48px;
    &:before {
      position: absolute;
      top: 0px;
      content: '';
      left: calc(var(--spacing-2) * -1);
      width: 20px;
      height: calc(100% + 10px);
      opacity: 0.16;
      background: linear-gradient(90deg, transparent, black);
    }
  }
}

.carousel_list {
  transform: translate3d(0, 0, 0); // just for Safari z-index issue on iOS 13
  -webkit-transform: translate3d(0, 0, 0); // just for Safari z-index issue on iOS 13
  overflow-x: auto;
  scroll-snap-type: none;
  margin: var(--spacing-3) 0;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
  @media (min-width: 768px) {
    display: flex;
    height: 100%;
    margin: 0;
  }
}

.carousel_slide {
  scroll-snap-align: start;
  flex-shrink: 0;
  color: var(--colour-utility-action);
  font-weight: var(--font-weight-semibold);
  text-decoration-thickness: 2px;
  padding: var(--spacing-1);
  padding-left: var(--spacing-2);
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  width: 100%;
  display: flex;
  text-decoration: none;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;

  &.selected {
    background-color: var(--colour-utility-white);
    color: var(--colour-utility-black);
    text-decoration: none;
    font-weight: var(--font-weight-regular);
    border-bottom: thick solid black;
  }

  &.desktop {
    width: 100%;
    &:first-child {
      padding-top: 20px;
    }
    &:last-child {
      padding-bottom: 20px;
    }
    padding: 10px 20px 10px;
  }

  .account-name {
    text-decoration: underline;
  }
  .account-no {
    font-size: var(--font-size-small);
    color: var(--colour-utility-base);
  }

  @media (min-width: 768px) {
    width: auto;
    justify-content: center;
    padding: 20px;
  }
}

.view-all-btn {
  position: relative;
  left: -20px;
  padding: var(--spacing-half);
  border-radius: 50%;
  background-color: var(--colour-utility-action);
}

.desktop-list-view {
  position: fixed;
  top: 80px;
  left: 50%;
  max-width: 320px;
  width: 95%;
  z-index: 102;
  background-color: var(--colour-panel-action);
  border-radius: var(--border-radius-1);
  transform: translateX(-50%);
  overflow-x: hidden;
  > div {
    max-height: calc(((100vh - 80px) / 10) * 9);
    overflow-y: auto;
  }
}

.rolldown_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-2) 0;
}

.rollup-mobile-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-1);
}
</style>
